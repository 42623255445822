import React from 'react'
import Layout from '../components/layout/Layout'
import SEO from '../components/SEO'
import NewsletterSuccess from '../components/NewsletterSuccess'

const NewsletterPage = () => (
    <Layout headerMode="shop">
        <SEO title="Zapisano do newslettera" />
        <NewsletterSuccess />
    </Layout>
)

export default NewsletterPage
