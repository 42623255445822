import React from 'react'
import { Link } from 'gatsby'
import { ColumnWrapper } from './styled-components/Wrappers.styled'
import { H2Section } from './styled-components/Headings.styled'
import Button from './styled-components/Button'

const NewsletterSuccess = () => {
    return (
        <ColumnWrapper width="960px" height="450px" margin="auto">
            <H2Section>Dziękujemy!</H2Section>
            <div
                style={{
                    fontFamily: 'Futura T OT',
                    margin: '0 auto 30px',
                    textAlign: 'center',
                }}
            >
                <p>Zasubskrybowałeś nasz newsletter.</p>
                <p>
                    Sprawdź skrzynkę pocztową, aby potwierdzić podany adres
                    email.
                </p>
            </div>
            <Button as={Link} to="/" aria-label="Wróć do strony głównej">
                Wróć do strony głównej
            </Button>
        </ColumnWrapper>
    )
}

export default NewsletterSuccess
